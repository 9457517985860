import { IconBase } from './icon-base.js';

export class FontAwesome6 extends IconBase {
    static stylesheet =
        '<link rel="stylesheet" href="https://kit.fontawesome.com/9378b72a97.css" crossorigin="anonymous">';
    static initial_element = '<span id="icon"></span>';

    _translate_icon_name(name) {
        // convert from "standard" dk-names to material-icons names
        const values = {
            remove: 'trash',
        };
        return values[name] || name;
    }

    /**
     * For the most common brands we autodetect them, so the user doesn't
     * have to specify <dk-icon brand value="facebook"> or 
     * <dk-icon value="facebook:brand">.  This is a convenience feature,
     * and the users are generally encouraged to specify that the icon is a 
     * brand.
     */
    _auto_brand_detection() {
        return [
            'facebook',
            'twitter',
            'instagram',
            'youtube',
            'linkedin',
            'github',
            'gitlab',
            'bitbucket',
            'google',
            'amazon',
            'microsoft',
            'apple',            
        ].filter(brand => this._value.includes(brand)).length > 0;
    }

    _render_inner_html() {
        let classes = '';
        let css = {};

        if (this.fw) classes += ' fa-fw';
        if (this.li) {
            classes += ' fa-li';
            css.top = 0;
        }
        if (this.animation != null) {
            this.animation.split(' ').forEach((anim) => {
                classes += ` fa-${anim}`;
            });
        }
        if (this.rotate != null) {
            classes += ` fa-rotate-by`;
            css['--fa-rotate-angle'] = `${this.rotate}deg`;
        }
        if (this._border) classes += ' fa-border';
        if (this._inverse) classes += ' fa-inverse';

        let icon_style = 'regular';
        let sharp = '';
        let brand = '';
        // let fontsize = '';

        if (this._solid) icon_style = 'solid';
        if (this._regular) icon_style = 'regular';
        if (this._light) icon_style = 'light';
        if (this._thin) icon_style = 'thin';
        if (this._duotone) icon_style = 'duotone';
        if (this._sharp) sharp = 'fa-sharp';
        if (this.brand || this._auto_brand_detection()) brand = 'fa-brands';

        // fa6 understand all size modifiers natively.
        if (this.size != null) {
            classes += ` fa-${this.size}`;
        }
        let css_str = '';
        for (const [key, value] of Object.entries(css)) {
            css_str += `${key}:${value};`;
        }
        let style = '';
        if (css_str.length > 0) style = `style="${css_str}"`;

        return `<i class="${sharp} ${brand} ${classes} fa-${icon_style} fa-${this._value}" ${style}></i>`;
    }
}
