import { IconBase } from './icon-base.js';

export class FontAwesome4 extends IconBase {
    // tag = 'fa4';
    static stylesheet =
        '<link rel="stylesheet" href="https://static.datakortet.no/font/fa470/css/font-awesome.css">';
    static initial_element = '<span id="icon"></span>';

    _translate_icon_name(name) {
        // convert from "standard" dk-names to library icon names
        const values = {
            remove: 'trash-o',
            cancel: 'times-circle-o',
            up: 'chevron-up',
            down: 'chevron-down',
            'star-of-life': 'certificate',
        };
        return values[name] || name;
    }

    _render_inner_html() {
        let classes = '';
        let css = {};

        if (this.fw) classes += ' fa-fw';  // text-align: center; width:1.25em;
        if (this.li) classes += ' fa-li';  // padding-left: 2em; list-style-type: none;

        if (this.animation != null) {
            this.animation.split(' ').forEach((anim) => {
                classes += ` fa-${anim}`;
            });
        }
        if (this.rotate != null) {
            css.transform = `rotate(${this.rotate}deg)`;
        }
        if (this._border) classes += ' fa-border';
        if (this._inverse) classes += ' fa-inverse';

        if (this.size != null) {
            switch (this.size) {
                case '2xs':
                case 'xs':
                case 'sm':
                case 'lg':
                case '6x':
                case '7x':
                case '8x':
                case '9x':
                case '10x':
                    css['font-size'] = IconBase.fontsize[this.size];
                    break;
                default:
                    classes += ` fa-${this.size}`;
                    break;
            }
        }
        let css_str = '';
        for (const [key, value] of Object.entries(css)) {
            css_str += `${key}:${value};`;
        }
        let style = '';
        // console.log('this._border=', this._border, classes);
        if (css_str.length > 0) style = `style="${css_str}"`;
        return `<i class="fa ${classes} fa-${this._value}" ${style}></i>`;
    }
}
