export class IconBase extends HTMLElement {
    // static stylesheet = '<link ...>';
    // static initial_element = '<... id="icon"></...>

    constructor() {
        super();
        this._value = this.textContent;
        this.attachShadow({ mode: 'open' });
        this._connected = false;
        // this._debug = this.constructor?.debug;
        this.brand = false;
        this._debug = this.constructor && this.constructor.debug ? this.constructor.debug : null;
        this._size = null;
        this.fw = false;
        this.li = false;
        this.animation = null;
        this._rotate = null;

        this._inverse = false;
        this._border = false;
        this._solid = false;
        this._regular = false;
        this._duotone = false;
        this._thin = false;
        this._sharp = false;
        this._light = false;


        this.shadowRoot.innerHTML = `
            ${this.constructor.stylesheet}
            ${this.constructor.initial_element}
        `;
        this._slot = this.shadowRoot.querySelector('#icon');
    }

    get inverse() { return this._inverse; }
    set inverse(v) { this._inverse = v; this.render(); }
    get border() { return this._border; }
    set border(v) { this._border = v; this.render(); }
    get solid() { return this._solid; }
    set solid(v) { this._solid = v; this.render(); }
    get regular() { return this._regular; }
    set regular(v) { this._regular = v; this.render(); }
    get duotone() { return this._duotone; }
    set duotone(v) { this._duotone = v; this.render(); }
    get thin() { return this._thin; }
    set thin(v) { this._thin = v; this.render(); }
    get sharp() { return this._sharp; }
    set sharp(v) { this._sharp = v; this.render(); }
    get light() { return this._light; }
    set light(v) { this._light = v; this.render(); }

    static get observedAttributes() {
        return [
            'value',
            'fw',       // fixed width
            'brand',    // brand icon (fa6 requires an extra class)
            'li',       // list item
            'animation',
            // 'spin',     // spin animation
            // 'pulse',    // pulse animation
            'rotate',
            'border',
            'inverse',
            'solid',
            'regular',
            'duotone',
            'thin',
            'light',
            'sharp',
            'size',
            '2xs',
            'xs',
            'sm',
            'lg',
            'xl',
            '1x',
            '2x',
            '3x',
            '4x',
            '5x',
            '6x',
            '7x',
            '8x',
            '9x',
            '10x',
        ];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        // always reflect attribute to property
        // (https://open-wc.org/guides/knowledge/attributes-and-properties/#when-to-use-what)
        if (this._debug) console.log(`attributeChangedCallback(${name}, ${oldValue}, ${newValue})`, newValue);
        switch (name) {
            case 'value':
                this.value = newValue;
                break;
            case 'size':
                this.size = newValue;
                break;
            case 'animation':
                this.animation = newValue;
                break;
            case 'rotate':
                this.rotate = newValue;
                break;
            default:
                if (Object.keys(IconBase.fontsize).includes(name)) {
                    this.size = name;
                } else {
                    this[name] = newValue !== null;
                }
                break;
        }
        this.render();
    }

    connectedCallback() {
        // console.log('connectedCallback');
        this._connected = true;
        this.render();
    }

    static fontsize = {
        '2xs': '0.625em',
        xs: '0.75em',
        sm: '0.875em',
        lg: '1.33333333em',  // fa4=1.3, fa6=1.25...
        xl: '1.5em',
        '1x': '1em',
        '2x': '2em',
        '3x': '3em',
        '4x': '4em',
        '5x': '5em',
        '6x': '6em',
        '7x': '7em',
        '8x': '8em',
        '9x': '9em',
        '10x': '10em',
    };

    set_modifiers(modifiers) {
        const size_modifiers = [
            '2xs', 'xs', 'sm', 'lg', 'xl', '2xl',
            '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'];
        modifiers.forEach(modifier => {
            if (size_modifiers.includes(modifier)) {
                this.size = modifier;
            }
        });
        const icon_style_modifiers = ['solid', 'regular', 'duotone', 'thin', 'light', 'sharp', 'brand'];
        modifiers.forEach(modifier => {
            if (icon_style_modifiers.includes(modifier)) {
                this[modifier] = true;
            }
        });
        this.fw = this.fw || modifiers.includes('fw');
        this.li = this.li || modifiers.includes('li');
        this._border = this._border || modifiers.includes('border');
    }

    parse_icon_value(value) {
        // allow both "key:2x:fw" and "key:2x,fw"
        let [name, ...modifier_list] = (value || "").split(':');
        const modifier_spec = modifier_list.join(',');              // string
        const modifiers = modifier_spec.split(',').filter(x => !!x); //.map(attr => 'xxxxdkicon-' + attr);
        this.set_modifiers(modifiers);
        return name;
    }

    /** Translate from "standard" dk-names to library icon names
     *
     * @param name
     * @returns {string}
     * @private
     */
    _translate_icon_name(name) {
        return name;
    }

    get value() {
        if (this._value === null)
            this._value = this._translate_icon_name(this.getAttribute('value'));
        return this._value;
    }

    set value(value) {
        // don't reflect property to attribute
        // (https://open-wc.org/guides/knowledge/attributes-and-properties/#when-to-use-what)
        // this.setAttribute('value', value);
        const icon_name = this.parse_icon_value(value);
        this._value = this._translate_icon_name(icon_name);
        if (this._debug) console.log(`set value: ${this._value}`);
        this.render();
    }

    get rotate() {
        return this._rotate;
    }

    /**
     * @param {int} angle
     */
    set rotate(angle) {
        this._rotate = angle;
        this.render();
    }

    get size() {
        return this._size;
    }

    set size(size) {
        this._size = size;
        this.render();
    }

    /** Override this method to render the icon
     *
     * @returns {string}
     * @private
     */
    _render_inner_html() {
        return 'missing _render_inner_html()';
    }

    render() {
        if (!this._connected) return;
        this.style.display = 'inline-block';
        if (this.shadowRoot) {
            this._slot.innerHTML = this._render_inner_html();
        }
    }
}
