import { IconBase } from './icon-base.js';

export class MaterialIcons extends IconBase {
    static stylesheet = `
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

    `;
    // <style>
    // .material-icons-outlined {
    //     font-variation-settings:
    //         'FILL' 0,
    //     'wght' 400,
    //     'GRAD' 0,
    //     'opsz' 48;
    // }
    // </style>
    static initial_element = '<span id="icon"></span>';

    _translate_icon_name(name) {
        // convert from "standard" dk-names to material-icons names
        const values = {
            remove: 'restore_from_trash',
            'star-of-life': 'emergency',
        };
        return values[name] || name;
    }

    _render_inner_html() {
        // material icons override the font-size in their stylesheet, so we need to
        // set it explicitly here based on the current dk-icon font-size.
        this._slot.style.display = 'inline-block';
        // let classes = '';
        let css = {};

        if (this.size != null) {
            css['font-size'] = IconBase.fontsize[this.size];
        }
        if (this._border) {
            css.border = 'var(--fa-border-width, .08em) var(--fa-border-style, solid) var(--fa-border-color, #eee)';
            css['border-radius'] = 'var(--fa-border-radius, .1em)';
            css.padding = 'var(--fa-border-padding, .125em .125em .125em)';
        }


        // let fontsize;
        // if (this.size != null) {
        //     fontsize = this.size;
        // } else {
        //     const style = window
        //         .getComputedStyle(this, null)
        //         .getPropertyValue('font-size');
        //     // console.log('fontsize from getcomputedstyle', style);
        //     fontsize = style;
        //     `${parseFloat(style)}px`;
        // }
        // console.log(this._value, `solid: ${this._solid}, regular: ${this._regular}, light: ${this._light}, thin: ${this._thin}`);
        let wt = 400;
        if (this._solid) wt = 700;
        if (this._regular) wt = 400;
        if (this._light) wt = 300;
        if (this._thin) wt = 100;
        css['font-variation-settings'] = `'FILL' ${this._solid ? 1 : 0}, 'wght' ${wt}, 'GRAD' 0, 'opsz' 48`;
        css['font-weight'] = wt;
        css['vertical-align'] = 'middle';
        // css['line-height'] = '1';

        let css_str = '';
        for (const [key, value] of Object.entries(css)) {
            css_str += `${key}:${value};`;
        }
        let style = '';
        if (css_str.length > 0) style = `style="${css_str}"`;


        return `<span ${style} class="material-symbols-outlined">${this._value}</span>`;
    }
}
